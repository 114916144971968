import React from 'react'
import availableLangs from '@localization/availableLangs'
import SeoContainer from '@containers/SeoContainer'
import TopInfluencesPageContainer from '../../components/containers/pagesContainers/TopInfluencesPageContainer'

const TopInfluencesPage = () => {
  return (
    <>
      <SeoContainer
        pageLang={availableLangs.en}
        pagePath='top'
        title='Instagram Search | Find Influencers For Your Brand'
        description='Find Top influencers for your brand on YouTube, Instagram, TikTok, and more. Search for top ambassadors within your network.'
      />
      <TopInfluencesPageContainer />
    </>
  )
}

export default TopInfluencesPage
